/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  text-align: center;
  /* Add a relative position to contain the background */
  position: relative;
  overflow: hidden;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255,0,150,0.7), rgba(0,204,255,0.7));
  z-index: -1; /* Make sure it's behind other content */
  animation: App-background-spin infinite 30s linear;
  /* Adding vendor prefixes for better browser support */
  @-webkit-keyframes App-background-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes App-background-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes App-background-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes App-background-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.App-header {
  background-color: rgba(40, 44, 52, 0.8); /* 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  transition: color 0.3s ease; /* Smooth color transition */
}

.App-link:hover {
  color: #21a1f1; 
}



.app-container {
  position: relative;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-background,
.right-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1; 
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1.5; 
  margin-top: 2rem;
}

.left-background {
  left: 0;
  width: 15rem; 
  background-image: url('./assets/LeftImage.png');
  position: absolute;
  max-height: 22rem;
  
 /* width: 370px;
height: 434px;
left: -80px;
top: -81px; */


}

.right-background {
  right: 0;
  width: 18rem; 
  max-height: 22rem;
  background-image: url('./assets/RightImage.png');
}

@media (max-width: 767px) {
  .left-background {
    width: 10rem;
    max-height: 15rem; 
    left: -40px; 
    top: -30px; 
  }
  
  .right-background {
    width: 12rem; 
    max-height: 15rem; 
    right: -40px; 
    top: -30px;
  }
}

.content {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow-y: auto; 
}
