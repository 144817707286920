body {

  margin: 0;
  padding: 0%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url('./assets/homeImg.gif') no-repeat fixed; */
  background-size: cover; 
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-menu-button {
  display: none; 
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
}

.ant-layout{
  background-color: transparent !important;
}

.custom-layout{
  padding: 3.5rem 3rem 1rem 3rem;
  position: absolute;
  background: none !important;
  background-color: transparent !important;
  justify-content: center;
  text-align: justify;
  align-items: center;
  /* margin:1rem; */
  margin-top: 1rem !important;
  margin-bottom: 10px !important;
  /* overflow: hidden; */
  /* overflow-x: hidden;
  overflow-y: hidden; */
  }


.custom-input {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
}



/* Define the scrollbar styles */
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  /* Width of the scrollbar */
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #6e6f6f;
  /* Color of the scrollbar track */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: rgb(34, 5, 88);
  border-radius: 5px;
  /* Color of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80a870;
  /* Color of the scrollbar handle on hover */
}



/* table design */

table .ant-table-thead .ant-table-cell {
  background: rgba(0, 60, 93, 0.05) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.TableBorder {
  border-top-left-radius:0px !important;
  border-top-right-radius: 0px !important;
  border-bottom: 2px solid #482E51 !important;
  overflow: hidden;
}

.ant-table-tbody>tr>td {
  border-color: #dde1ef !important;
}

:where(.css-dev-only-do-not-override-17sk1pa).ant-table-wrapper .ant-table-container {
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}

.ant-table-thead .ant-table-cell {
  padding: 0.5rem !important;
}

.ant-table-cell {
  /* color: #482E51 !important; */
  padding: 0.7rem !important;
}

.ant-statistic-content-value {
  font-weight: 600;
}


/* Hide all columns by default on small screens */
@media (max-width: 768px) {
  /* .ant-table .column-basic, */
  .ant-table .column-advance,
  .ant-table .column-enterprise {
    display: none;
  }

  .ant-table .column-feature,
  .ant-table .column-basic {
    display: table-cell; 
  }
}

/* Show all columns on larger screens */
@media (min-width: 769px) {
  .ant-table .column-feature,
  .ant-table .column-basic,
  .ant-table .column-advance,
  .ant-table .column-enterprise {
    display: table-cell;
  }
}


/* loading page custom style */
.ant-spin-dot i {
  background-color: white !important;
}


.ant-spin {
  background-color: transparent !important;
  border-radius: 50%;
}

.ant-spin, .ant-spin-container, .ant-spin-nested-loading {
  background-color: none !important;
  border-radius: 50%;

}

.ant-spin-text {
  color: white !important;
  font-size: 1rem !important;
  font-weight: bold;
  font-family: Roboto,sans-serif;
}